import cheminer from "../../../assets/images/cheminer/medium/1_cheminer.webp";
import douter from "../../../assets/images/cheminer/medium/2_douter.webp";
import renoncer from "../../../assets/images/cheminer/medium/3_renoncer.webp";
import faireCorps from "../../../assets/images/cheminer/medium/4_faire-corps.webp";
import tisser from "../../../assets/images/cheminer/medium/5_tisser.webp";

const chapters = [
    {
        title: "Cheminer",
        text: (
            <div>
                <p>
                    Bienvenue sur{" "}
                    <span className="font-bold">
                        un chemin de questionnements
                    </span>{" "}
                    à propos du système de santé.
                </p>
                <br />
                <p>Voici quelques pistes pour imaginer autre chose.</p>
                <br />
                <p>
                    Un lieu ressource pour donner de la visibilité à des
                    personnes et collectifs qui tentent de penser et panser le
                    soin au sein de notre système de santé.
                </p>
                <br />
                <p className="font-bold">Tisser des liens AVEC SOIN.</p>
                <br />
                <p>Le soin comme intention de faire société.</p>
            </div>
        ),
        imagePath: cheminer,
        alt: "cheminer",
        col: 12,
        start: 1,
        imagePosition: "left",
    },
    {
        title: "Douter",
        text: (
            <div>
                <p>
                    Des extraits du livre{" "}
                    <span className="italic">
                        Mon blanc de travail, un cri du care
                    </span>
                    .
                </p>
                <br />
                <p>
                    Journal de bord du COVID d'une médecin
                    anesthésiste-réanimatrice qui doute de l'essence de son
                    métier.
                </p>
            </div>
        ),
        imagePath: douter,
        alt: "couverture Mon Blanc de Travail",
        col: 12,
        start: 1,
        imagePosition: "right",
    },
    {
        title: "Renoncer",
        text: (
            <div>
                <p>
                    <span className="italic">J'ai rendu la blouse</span>, un
                    podcast en 12 épisodes.
                </p>
                <br />
                <p>
                    Raconter pourquoi j'ai démissionné d'un métier que j'aimais
                    tant, médecin anesthésiste-réanimatrice, en mêlant ma voix
                    aux portraits de 12 soignantes et soignants.
                </p>
            </div>
        ),
        imagePath: renoncer,
        alt: "J'ai rendu la blouse",
        col: 12,
        start: 1,
        imagePosition: "left",
    },
    {
        title: "Faire Corps",
        text: (
            <div>
                <p>
                    Et si tout n'était que prétexte à la{" "}
                    <span className="font-bold">rencontre</span>.
                </p>
                <br />
                <p>
                    Quelques unes des personnes, projets, collectifs qui m'ont
                    accueillie, fait grandir, soutenue.
                </p>
                <br />
                <p>Qui m'ont réanimée et continuent de m'animer. </p>
            </div>
        ),
        imagePath: faireCorps,
        alt: "Faire Corps",
        col: 12,
        start: 1,
        imagePosition: "right",
    },
    {
        title: "Tisser",
        text: (
            <div>
                <p>
                    Une carte partielle et partiale des{" "}
                    <span className="font-bold">émergences en santé.</span>
                </p>
                <br />
                <p className="italic">
                    "La carte n'est pas le territoire, mais elle aide à se
                    repérer."
                </p>
                <br />
                <p>
                    Santé intégrative, planétaire, communautaire, quel que soit
                    le nom : <br /> des personnes, lieux, associations,
                    collectifs, médias, qui esquissent un visage de la santé en
                    recomposition.
                </p>
                <br />
                <p>
                    Soutenir l'existant ou nourrir l'émergent, peu importe ! Du
                    moment que l'intention de prendre soin est là.
                </p>
            </div>
        ),
        imagePath: tisser,
        alt: "Tisser",
        col: 12,
        start: 1,
        imagePosition: "left",
    },
];

export default chapters;
