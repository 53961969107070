import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Outlet, ScrollRestoration } from "react-router-dom";

function Root() {
    return (
        <div className="m-0 box-border overflow-x-hidden p-0">
            <Header />
            <main className="mx-auto w-full">
                <Outlet />
            </main>
            <Footer />
            <ScrollRestoration />
        </div>
    );
}

export default Root;
