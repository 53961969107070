import { Link } from "react-router-dom";
import "../../styles.css";

function MentionsLegales() {
    return (
        <div className="bg-primary-light">
            <div className="grid-container container">
                <div className="grid-item">
                    <h1 className="font-hand text-center text-5xl">
                        Mentions légales
                    </h1>
                    <p className="text-center">Version du 10 décembre 2024</p>
                    <h3 className="font-text py-4 text-2xl">
                        Édition du site Internet
                    </h3>
                    <p>
                        Le site web accessible à l’adresse{" "}
                        <a
                            href="https://monblancdetravail.fr"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                        >
                            https://monblancdetravail.fr
                        </a>{" "}
                        (ci-après "le Site") est édité par :
                    </p>
                    <ul>
                        <li>Nom : Margot SMIRDEC</li>
                        <li>Email : contact@monblancdetravail.fr</li>
                    </ul>

                    <h3 className="font-text py-4 text-2xl">
                        Hébergeur du site Internet
                    </h3>
                    <p>
                        Le Site est hébergé par o2switch, SAS au capital de 100
                        000 €, dont le siège social est domicilié au :
                    </p>
                    <ul>
                        <li>
                            Adresse : Chemin des Pardiaux, 63000
                            Clermont-Ferrand, France
                        </li>
                        <li>N° SIRET : 510 909 807 00024</li>
                        <li>Téléphone : +33 4 44 44 60 40</li>
                        <li>Email : support@o2switch.fr</li>
                    </ul>
                    <h3 className="font-text py-4 text-2xl">
                        Respect de la propriété intellectuelle
                    </h3>
                    <p>
                        Tous les contenus présents sur le Site (textes, images,
                        logos, vidéos, etc.) sont protégés par les lois en
                        vigueur sur la propriété intellectuelle. Toute
                        reproduction, représentation, modification, publication
                        ou adaptation de tout ou partie des éléments du Site,
                        quel que soit le moyen ou le procédé utilisé, est
                        interdite sauf autorisation écrite préalable de
                        l’Éditeur.
                    </p>
                    <h3 className="font-text py-4 text-2xl">Responsabilité</h3>
                    <p>
                        L’Éditeur s’efforce de fournir sur le Site des
                        informations aussi précises que possible. Toutefois, il
                        ne pourra être tenu responsable des omissions,
                        inexactitudes ou carences dans la mise à jour, qu’elles
                        soient de son fait ou du fait des tiers partenaires qui
                        lui fournissent ces informations.
                    </p>
                    <h3 className="font-text py-4 text-2xl">
                        Liens hypertextes
                    </h3>
                    <p>
                        Le Site peut contenir des liens hypertextes vers
                        d’autres sites web. L’Éditeur n’assume aucune
                        responsabilité quant au contenu de ces sites tiers.
                    </p>
                    <h3 className="font-text py-4 text-2xl">
                        Données personnelles
                    </h3>
                    <p>
                        Pour des informations détaillées sur la collecte et le
                        traitement de vos données personnelles, veuillez
                        consulter notre{" "}
                        <Link
                            to="/politique-de-confidentialite"
                            className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                        >
                            Politique de confidentialité
                        </Link>
                        .
                    </p>
                    <h3 className="font-text py-4 text-2xl">Contact</h3>
                    <p>
                        Pour toute question ou demande d’information concernant
                        le contenu du Site, vous pouvez me contacter via le{" "}
                        <Link
                            to="/contact"
                            className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                        >
                            formulaire de contact
                        </Link>
                        .
                    </p>
                </div>
            </div>
        </div>
    );
}

export default MentionsLegales;
