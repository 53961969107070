function BurgerIcon({ toggleMenu, isOpen }) {
    return (
        <div className="md:hidden">
            <button
                id="menu-button"
                className="focus:outline-none"
                onClick={toggleMenu}
            >
                {isOpen ? (
                    <svg
                        className="text-primary-bold h-12 w-12 p-0 sm:h-14 sm:w-14"
                        viewBox="0 0 72 72"
                        xmlns="http://www.w3.org/2000/svg"
                        stroke="currentColor"
                    >
                        <g id="line">
                            <line
                                x1="16"
                                x2="56"
                                y1="16"
                                y2="56"
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="3"
                            />
                            <line
                                x1="56"
                                x2="16"
                                y1="16"
                                y2="56"
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="3"
                            />
                        </g>
                    </svg>
                ) : (
                    <svg
                        className="text-primary-bold h-12 w-12 p-0 sm:h-14 sm:w-14"
                        viewBox="0 0 72 72"
                        xmlns="http://www.w3.org/2000/svg"
                        stroke="currentColor"
                    >
                        <g id="line">
                            <line
                                x1="16"
                                x2="56"
                                y1="26"
                                y2="26"
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="3"
                            />
                            <line
                                x1="16"
                                x2="56"
                                y1="36"
                                y2="36"
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="3"
                            />
                            <line
                                x1="16"
                                x2="56"
                                y1="46"
                                y2="46"
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="3"
                            />
                        </g>
                    </svg>
                )}
            </button>
        </div>
    );
}

export default BurgerIcon;
