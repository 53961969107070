import { Link } from "react-router-dom";

function FooterLink({ name, link }) {
    return (
        <Link
            to={link}
            className="hover:text-ternary-bold duration-400 uppercase transition-colors ease-in-out"
        >
            {name}
        </Link>
    );
}

export default FooterLink;
