import { Link } from "react-router-dom";
import pageLinks from "../../pageLinks";

function NavBarLink({ link, title }) {
    return (
        <Link
            className="hover:text-primary-bold font-text mx-4 text-center text-lg uppercase text-slate-500 transition duration-300 ease-in-out"
            to={link}
        >
            {title}
        </Link>
    );
}

function Navbar() {
    return (
        <div className="ml-auto flex">
            {pageLinks.map((page, index) => (
                <NavBarLink title={page.title} link={page.link} key={index} />
            ))}
        </div>
    );
}

export default Navbar;
