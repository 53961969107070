import "../../styles.css";

function Partenaires() {
    return (
        <div className="bg-primary-light">
            <div className="grid-container container">
                <div className="grid-item">
                    <h1 className="font-hand text-center text-5xl">
                        Partenaires
                    </h1>
                    <p className="text-center">Version du 10 décembre 2024</p>

                    <h3 className="font-text py-4 text-2xl">
                        Développement du site
                    </h3>
                    <p>
                        Le site a été conçu et développé par Bogdan SMIRDEC, mon
                        frère, en utilisant les technologies ReactJS et
                        TailwindCSS.
                        <br />
                        Merci aux ressources en ligne et communautés pour leur
                        soutien et inspiration.
                        <br />
                        Mentions particulières aux sites de{" "}
                        <a
                            href="https://santeplanetaire.org/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                        >
                            l'Alliance Santé Planétaire
                        </a>
                        , ainsi que{" "}
                        <a
                            href="https://www.mbsr-paris.fr/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                        >
                            Soizic Michelot
                        </a>
                        , dont nous nous sommes particulièrement inspiré.
                    </p>

                    <h3 className="font-text py-4 text-2xl">Illustrations</h3>
                    <p>
                        Les illustrations utilisées sur ce site proviennent de
                        sources libres de droits.
                        <br />
                        Merci aux artistes pour leur travail inspirant.
                    </p>

                    <h3 className="font-text py-4 text-2xl">Photos</h3>
                    <p>
                        Les photos présentes sur le site proviennent de banques
                        d'images libres de droits ou directement de ma
                        bibliothèque personnelle.
                    </p>

                    <h3 className="font-text py-4 text-2xl">Remerciements</h3>
                    <p>
                        Je tiens à remercier toutes les personnes et communautés
                        qui ont contribué, directement ou indirectement, à la
                        réalisation de ce site. Votre aide et votre soutien ont
                        été précieux.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Partenaires;
