import { Link } from "react-router-dom";
import "../../styles.css";

function PolitiqueDeConfidentialite() {
    return (
        <div className="bg-primary-light">
            <div className="grid-container container">
                <div className="grid-item">
                    <h1 className="font-hand text-center text-5xl">
                        Politique de confidentialité
                    </h1>
                    <p className="text-center">Version du 10 décembre 2024</p>
                    <h3 className="font-text py-4 text-2xl">
                        Collecte des données personnelles
                    </h3>
                    <p>
                        Le site web accessible à l’adresse{" "}
                        <a
                            href="https://monblancdetravail.fr"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                        >
                            https://monblancdetravail.fr
                        </a>{" "}
                        (ci-après "le Site") collecte les données personnelles
                        suivantes via son formulaire de contact :
                    </p>
                    <ul>
                        <li>Nom et prénom</li>
                        <li>Adresse email</li>
                    </ul>
                    <p>
                        Ces données sont fournies volontairement par les
                        utilisateurs lors de l’utilisation du formulaire de
                        contact disponible sur le Site.
                    </p>
                    <h3 className="font-text py-4 text-2xl">
                        Finalité du traitement
                    </h3>
                    <p>
                        Les données collectées sont utilisées uniquement dans le
                        cadre des échanges nécessaires pour répondre aux
                        demandes des utilisateurs. Elles ne sont pas utilisées à
                        des fins commerciales ou transmises à des tiers.
                    </p>
                    <h3 className="font-text py-4 text-2xl">
                        Conservation des données
                    </h3>
                    <p>
                        Les données collectées via le formulaire de contact sont
                        conservées pendant une durée maximale de 1 an à compter
                        de la réception du message. Passé ce délai, elles sont
                        supprimées de manière sécurisée.
                    </p>
                    <h3 className="font-text py-4 text-2xl">
                        Droits des utilisateurs
                    </h3>
                    <p>
                        Conformément au Règlement Général sur la Protection des
                        Données (RGPD), les utilisateurs disposent des droits
                        suivants :
                    </p>
                    <ul>
                        <li>
                            Droit d’accès : obtenir une copie des données
                            personnelles les concernant.
                        </li>
                        <li>
                            Droit de rectification : demander la modification
                            des données inexactes ou incomplètes.
                        </li>
                        <li>
                            Droit de suppression : demander la suppression de
                            leurs données personnelles.
                        </li>
                        <li>
                            Droit d’opposition : s’opposer au traitement de
                            leurs données personnelles dans certains cas.
                        </li>
                    </ul>
                    <p>
                        Pour exercer ces droits, les utilisateurs peuvent
                        envoyer une demande par email à :{" "}
                        <a
                            href="mailto:contact@monblancdetravail.fr"
                            className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                        >
                            contact@monblancdetravail.fr
                        </a>
                        .
                    </p>
                    <h3 className="font-text py-4 text-2xl">
                        Sécurité des données
                    </h3>
                    <p>
                        L’Éditeur s’engage à mettre en œuvre toutes les mesures
                        techniques et organisationnelles nécessaires pour
                        protéger les données personnelles des utilisateurs
                        contre tout accès non autorisé, perte, altération ou
                        divulgation.
                    </p>
                    <h3 className="font-text py-4 text-2xl">
                        Modifications de la politique de confidentialité
                    </h3>
                    <p>
                        La présente politique de confidentialité peut être mise
                        à jour à tout moment pour se conformer aux évolutions
                        législatives ou réglementaires. Les modifications seront
                        publiées sur cette page avec la date de mise à jour.
                    </p>
                    <h3 className="font-text py-4 text-2xl">Contact</h3>
                    <p>
                        Pour toute question ou demande d’information concernant
                        la politique de confidentialité, vous pouvez me
                        contacter via le{" "}
                        <Link
                            to="/contact"
                            className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                        >
                            formulaire de contact
                        </Link>
                        .
                    </p>
                </div>
            </div>
        </div>
    );
}

export default PolitiqueDeConfidentialite;
