import React, { useState, useRef, useEffect, useMemo } from 'react';
import Petal from '../Petal';
import { getPetalPosition } from '../../utils/positionUtils';

const Map = ({ data }) => {
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const frameRef = useRef(null);

  // Get unique themes
  const themes = [...new Set(data.map(item => item.theme))];
  const centerTheme = "Faire corps";
  const surroundingThemes = themes.filter(theme => theme !== centerTheme);
  
  // Adjusted dimensions to fit grid layout
  const frameWidth = '100%';  // Will fit container width
  const frameHeight = 600;    // Reduced height
  const mapWidth = 700;       // Reduced for better petal spacing
  const mapHeight = 500;      // Reduced for better petal spacing

  // Calculate petal positions once and memoize them
  const petalPositions = useMemo(() => {
    return surroundingThemes.map((_, index) => 
      getPetalPosition(
        index,
        surroundingThemes.length,
        mapWidth,
        mapHeight
      )
    );
  }, [surroundingThemes.length, mapWidth, mapHeight]);

  const handleWheel = (e) => {
    const rect = frameRef.current.getBoundingClientRect();
    const isInsideFrame = (
      e.clientX >= rect.left &&
      e.clientX <= rect.right &&
      e.clientY >= rect.top &&
      e.clientY <= rect.bottom
    );

    if (isInsideFrame) {
      e.preventDefault();
      const zoomSensitivity = 0.1;
      const newScale = Math.max(0.5, Math.min(4, scale + (e.deltaY > 0 ? -zoomSensitivity : zoomSensitivity)));
      setScale(newScale);
    }
  };

  useEffect(() => {
    const frame = frameRef.current;
    if (!frame) return;

    const preventScroll = (e) => {
      const rect = frame.getBoundingClientRect();
      const isInsideFrame = (
        e.clientX >= rect.left &&
        e.clientX <= rect.right &&
        e.clientY >= rect.top &&
        e.clientY <= rect.bottom
      );

      if (isInsideFrame) {
        e.preventDefault();
      }
    };

    frame.addEventListener('wheel', preventScroll, { passive: false });

    return () => {
      frame.removeEventListener('wheel', preventScroll);
    };
  }, []);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setDragStart({
      x: e.clientX - position.x,
      y: e.clientY - position.y
    });
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      setPosition({
        x: e.clientX - dragStart.x,
        y: e.clientY - dragStart.y
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div 
      ref={frameRef}
      className="frame relative overflow-hidden mx-auto"
      style={{
        width: frameWidth,
        height: frameHeight,
        border: '2px solid black',
        cursor: isDragging ? 'grabbing' : 'grab'
      }}
      onWheel={handleWheel}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <div 
        className="mind-map absolute"
        style={{
          width: mapWidth,
          height: mapHeight,
          left: '50%',
          top: '50%',
          transform: `translate(-50%, -50%) translate(${position.x}px, ${position.y}px) scale(${scale})`,
          transition: isDragging ? 'none' : 'transform 0.1s ease-out'
        }}
      >
        {/* Center Petal */}
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <Petal 
            theme={centerTheme} 
            data={data} 
            key={centerTheme} 
            ellipseWidth={300} 
            ellipseHeight={190}
            scale={scale}
          />
        </div>
        
        {/* Surrounding Petals */}
        {surroundingThemes.map((theme, index) => {
          const position = petalPositions[index];
          
          return (
            <div
              key={theme}
              className="absolute"
              style={{
                left: `${position.x + mapWidth/2}px`,
                top: `${position.y + mapHeight/2}px`,
                transform: 'translate(-50%, -50%)',
              }}
            >
              <Petal 
                theme={theme} 
                data={data} 
                ellipseWidth={200} 
                ellipseHeight={130}
                scale={scale}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Map;
