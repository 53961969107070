import fullLogo from "../../../assets/images/full-logo.png";
import smallLogo from "../../../assets/images/small-logo.png";
import { Link } from "react-router-dom";
import "./style.css";

const MbdtLogo = ({ isSmallSize }) => {
    return (
        <div id="logo-container">
            <Link className="" to={"/"}>
                <img
                    src={smallLogo}
                    alt="Mon Blanc de Travail"
                    title="Mon Blanc de Travail"
                    className="h-12 w-14 md:hidden"
                />
                <img
                    src={fullLogo}
                    alt="Mon Blanc de Travail"
                    title="Mon Blanc de Travail"
                    className={`hidden md:block ${isSmallSize ? "reduced-logo" : "logo"}`}
                />
            </Link>
        </div>
    );
};

export default MbdtLogo;
