import { FaSpotify, FaDeezer } from "react-icons/fa";
import { SiApplepodcasts, SiPodcastaddict } from "react-icons/si";
import podcast from "../../../../assets/images/renoncer/medium/podcast.webp";
import PlayButton from "../../../../components/buttons/PlayButton";
import extract from "../../../../assets/audio/jai-rendu-la-blouse_trailer.wav";

const PodcastTrailer = () => {
    const backgroundImage = `linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0) 100%), url(${podcast})`;

    return (
        // Besoin décaler la tête de Margot de quelques pixels vers la gauche
        <div
            className="flex flex-row-reverse rounded-t-lg border border-b-0 border-gray-300 bg-white p-2 sm:p-3 md:p-4"
            style={{
                backgroundImage: backgroundImage,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
            }}
        >
            <div className="flex flex-col gap-2 p-4 md:w-1/2 md:gap-4">
                <div className="flex items-center justify-around">
                    <PlayButton track={extract} />
                    <h1 className="font-subtitle text-primary-bold pb-4 text-center text-2xl font-bold uppercase md:p-0">
                        J'ai rendu la blouse
                    </h1>
                </div>

                <div className="flex flex-col gap-4 rounded-xl bg-white/40 p-2 md:bg-white/0">
                    <div className="font-text text-justify text-sm md:text-base">
                        <p>
                            Je m’appelle Margot Smirdec. J’étais médecin
                            anesthésiste-réanimatrice et j’ai démissionné.
                            J’aimais profondément mon métier et pourtant je l’ai
                            quitté. Comme tant d’autres soignants et soignantes.{" "}
                            <br />
                            Pourquoi ? Je vous propose d’explorer cette question
                            ensemble, au fil de mon histoire et du récit
                            d’autres acteur·ices de santé, qui exercent encore,
                            pour nombre d’entre elles, non sans difficulté. Mais
                            pour combien de temps et à quel prix ? Et comment ne
                            serait-ce que se poser la question quand le métier
                            est vocation.
                        </p>
                    </div>
                    <div className="flex items-center justify-center space-x-4">
                        <a
                            href="https://open.spotify.com/show/7Ejr0qbYKBMnyMsPlkkWkk"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-green-500 hover:text-green-700"
                        >
                            <FaSpotify size={30} />
                        </a>
                        <a
                            href="https://www.deezer.com/fr/show/1001248341"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 hover:text-blue-700"
                        >
                            <FaDeezer size={30} />
                        </a>
                        <a
                            href="https://www.apple.com/apple-podcasts/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="none hidden text-purple-500 hover:text-purple-700"
                        >
                            <SiApplepodcasts size={30} />
                        </a>
                        <a
                            href="https://podcastaddict.com/podcast/j-ai-rendu-la-blouse/5379113"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-orange-500 hover:text-orange-700"
                        >
                            <SiPodcastaddict size={30} />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PodcastTrailer;
