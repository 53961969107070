import React from "react";
import { Link } from "react-router-dom";
import pageLinks from "../../../../layouts/Header/pageLinks";
import "./style.css";

function StepCard({ title, text, imagePath, alt, imagePosition, index }) {
    return (
        <div
            className={`flex flex-col rounded bg-white p-2 sm:p-3 md:flex-row lg:h-96 ${
                imagePosition === "left" ? "md:flex-row-reverse" : "md:flex-row"
            } md:rounded-lg md:p-4`}
        >
            <div className="grid gap-2 p-4 md:w-2/3 md:gap-4">
                <h1 className="font-hand pb-4 text-center text-3xl sm:text-5xl">
                    <Link
                        to={pageLinks[index].link}
                        className="hover:text-primary-bold inline-block transition-colors duration-200"
                    >
                        {title}
                    </Link>
                </h1>

                <div className="font-text text-left text-sm md:text-base">
                    {text}
                </div>
            </div>
            <div
                className="relative flex items-center justify-center p-4 md:w-1/3 md:p-0"
                loading="lazy"
            >
                <Link to={pageLinks[index].link} className="">
                    <img
                        src={imagePath}
                        alt={alt}
                        className={`${
                            title === "Douter"
                                ? "image-custom-height border"
                                : ""
                        } h-full w-full border-black object-cover`}
                    />
                </Link>
            </div>
        </div>
    );
}

export default StepCard;
