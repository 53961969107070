import React, { useMemo } from "react";
import PetalLink from "../PetalLink";
import { getLinkPosition } from "../../utils/positionUtils";

// Theme color mapping
const themeColors = {
    "Faire corps": "#FFE4E1", // Misty Rose
    "Santé intégrative": "#E6E6FA", // Lavender
    "Ethique": "#F0FFF0", // Honeydew
    "Soigner la mort": "#FFF0F5", // Lavender Blush
    "Santé des femmes": "#F5F5DC", // Beige
};

const Petal = ({ theme, data, ellipseWidth, ellipseHeight, scale = 1 }) => {
    const themeLinks = data.filter((item) => item.theme === theme);

    // Calculate positions once and memoize them
    const linkPositions = useMemo(() => {
        const positions = [];

        return themeLinks.map((_, index) => {
            const position = getLinkPosition(
                index,
                themeLinks.length,
                ellipseWidth,
                ellipseHeight,
                positions,
            );
            positions.push(position);
            return position;
        });
    }, [themeLinks.length, ellipseWidth, ellipseHeight]);

    // Calculate theme title opacity based on scale
    const titleOpacity = scale <= 1.5 ? 1 : Math.max(0, 2.5 - scale);

    return (
        <div
            className="petal relative"
            style={{
                width: ellipseWidth,
                height: ellipseHeight,
                backgroundColor: themeColors[theme] || "#F8F8F8",
                boxShadow: "0 0 20px rgba(0,0,0,0.1)",
                backdropFilter: "blur(5px)",
                border: "1px solid rgba(0,0,0,0.1)",
                borderRadius: "50%", // This creates a perfect ellipse when width != height
                overflow: "hidden", // Ensures content doesn't overflow the ellipse
            }}
        >
            <div
                className="theme-title absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-center font-bold text-gray-700 transition-opacity duration-300"
                style={{ 
                    fontSize: "0.8rem",
                    opacity: titleOpacity,
                    pointerEvents: titleOpacity < 0.1 ? 'none' : 'auto'
                }}
            >
                {theme}
            </div>
            {themeLinks.map((link, index) => {
                const { x, y, fontSize } = getLinkPosition(
                    index,
                    themeLinks.length,
                    ellipseWidth,
                    ellipseHeight,
                );

                return (
                    <div
                        key={link.id}
                        className="absolute -translate-x-1/2 -translate-y-1/2 transform"
                        style={{
                            left: `${x + ellipseWidth / 2}px`,
                            top: `${y + ellipseHeight / 2}px`,
                            opacity: scale < 1.2 ? 0.7 : 1, // Links become more visible on zoom
                        }}
                    >
                        <PetalLink
                            title={link.title}
                            link={link.url}
                            icon={link.icon || "•"}
                            logo={link.hasLogo}
                            logoLink={link.logoUrl}
                            fontSize={fontSize}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default Petal;
