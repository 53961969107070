// Fichier généré automatiquement - Ne pas modifier manuellement
export const data = [
  {
    "theme": "",
    "title": "Méditation et médecine : éloge du care ?",
    "url": "https://www.youtube.com/playlist?list=PLRHVM3XoG5iFt9j8zgTzXXKnxlBodUhoG",
    "icon": "bande de film",
    "assets": "",
    "react-icon": "BiSolidCameraMovie",
    "logo": "FALSE",
    "logoLink": "",
    "label": "Vidéo"
  },
  {
    "theme": "",
    "title": "La sociale",
    "url": "http://www.lasociale.fr",
    "icon": "bande de film",
    "assets": "",
    "react-icon": "BiSolidCameraMovie",
    "logo": "FALSE",
    "logoLink": "",
    "label": "Vidéo"
  },
  {
    "theme": "",
    "title": "Les défis du XXIème siècle : des enjeux systémiques",
    "url": "https://www.youtube.com/watch?v=rbDIFEtaMqs",
    "icon": "bande de film",
    "assets": "",
    "react-icon": "BiSolidCameraMovie",
    "logo": "FALSE",
    "logoLink": "",
    "label": "Vidéo"
  },
  {
    "theme": "",
    "title": "Un autre soin est possible",
    "url": "https://www.youtube.com/watch?v=TPn_edifie0",
    "icon": "bande de film",
    "assets": "",
    "react-icon": "BiSolidCameraMovie",
    "logo": "FALSE",
    "logoLink": "",
    "label": "Vidéo"
  },
  {
    "theme": "Faire corps",
    "title": "France Tiers Lieux",
    "url": "https://cartographie.francetierslieux.fr",
    "icon": "Globe",
    "assets": "https://media.istockphoto.com/id/962186144/fr/vectoriel/ic%C3%B4ne-de-globe-monde-logo-de-vector-de-la-terre-illustration-de-simbol-global-web.jpg?s=612x612&w=0&k=20&c=bms8ZjDNpv8d4ZnoCpe_ttLfxzx50Nuu_FbQjRx6w-k=",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "https://cartographie.francetierslieux.fr/upload/communecter/organizations/5ec3b895690864db108b46b8/LOGOCommunecter.png",
    "label": "Carte"
  },
  {
    "theme": "Faire corps",
    "title": "Transiscope",
    "url": "https://transiscope.org/carte-des-alternatives/",
    "icon": "Globe",
    "assets": "https://media.istockphoto.com/id/962186144/fr/vectoriel/ic%C3%B4ne-de-globe-monde-logo-de-vector-de-la-terre-illustration-de-simbol-global-web.jpg?s=612x612&w=0&k=20&c=bms8ZjDNpv8d4ZnoCpe_ttLfxzx50Nuu_FbQjRx6w-k=",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "https://transiscope.org/wp-content/uploads/2018/09/Transiscope-alt2-blanc-1.svg",
    "label": "Carte"
  },
  {
    "theme": "Faire corps",
    "title": "GT Santé Tiers-Lieux",
    "url": "https://tiers-lieux.fr/?Santé",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "FaPeopleGroup",
    "logo": "TRUE",
    "logoLink": "https://tiers-lieux.fr/files/PageTitre_Logo_site_bandeau150_20230203150545_20230203160555.png",
    "label": "Collectif, association"
  },
  {
    "theme": "Faire corps",
    "title": "Revue Pratiques",
    "url": "https://pratiques.fr",
    "icon": "Livres",
    "assets": "https://media.istockphoto.com/id/1295650475/fr/vectoriel/ensemble-de-croquis-de-livres-illustration-vectorielle.jpg?s=612x612&w=0&k=20&c=TBJYqFb52SBALzHJ-Qo1q8T3GIbgNquR0fUUiXOFcJM=",
    "react-icon": "FcReading",
    "logo": "FALSE",
    "logoLink": "",
    "label": "Livre, article"
  },
  {
    "theme": "Faire corps",
    "title": "EnCommuns",
    "url": "https://www.encommuns.net",
    "icon": "Livres",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Livre, article"
  },
  {
    "theme": "Faire corps",
    "title": "InterHop",
    "url": "https://interhop.org",
    "icon": "Ordinateur",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Numérique"
  },
  {
    "theme": "Faire corps",
    "title": "La Fabrique des Soignants",
    "url": "https://www.youtube.com/channel/UC5Jw_vLWT5XpgkDBeGZ4PFA",
    "icon": "2 yeux",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Media"
  },
  {
    "theme": "Faire corps",
    "title": "Wébinaire la Fabrique des santés",
    "url": "https://www.fabsan.cc/faire-tiers-lieux-en-sante",
    "icon": "2 yeux",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Media"
  },
  {
    "theme": "Faire corps",
    "title": "Lowpital",
    "url": "https://lowpital.care",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Faire corps",
    "title": "Répertoire de podcasts santé",
    "url": "https://www.podcast-sante.com",
    "icon": "Micro",
    "assets": "",
    "react-icon": "",
    "logo": "FALSE",
    "logoLink": "",
    "label": "Podcast"
  },
  {
    "theme": "Faire corps",
    "title": "Les Transformateurs",
    "url": "https://lowpital.care/podcast",
    "icon": "Micro",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Podcast"
  },
  {
    "theme": "Faire corps",
    "title": "Facteurs humains en santé",
    "url": "https://facteurshumainsensante.org",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Faire corps",
    "title": "Soignons Humain",
    "url": "https://www.soignonshumain.com",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Faire corps",
    "title": "L'humain au coeur du soin",
    "url": "https://humainaucoeurdusoin.fr",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "FALSE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Faire corps",
    "title": "Culture santé",
    "url": "https://culturesante.org",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Faire corps",
    "title": "France Assos Santé",
    "url": "https://www.france-assos-sante.org",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Faire corps",
    "title": "APAJH",
    "url": "https://www.apajh.org/#/",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Faire corps",
    "title": "La Maison Perchée",
    "url": "https://www.maisonperchee.org",
    "icon": "Maison",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Lieu"
  },
  {
    "theme": "Faire corps",
    "title": "Mūsae",
    "url": "https://musae-tomorrow.com",
    "icon": "2 yeux",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Media"
  },
  {
    "theme": "Faire corps",
    "title": "Petite Mû",
    "url": "https://www.petitemu.fr",
    "icon": "2 yeux",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Media"
  },
  {
    "theme": "Faire corps",
    "title": "Les jardins d'Haïti",
    "url": "https://jardinsdhaiti.fr",
    "icon": "Maison",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Lieu"
  },
  {
    "theme": "Faire corps",
    "title": "EHPAD Kersalic",
    "url": "https://kersalic.com",
    "icon": "Maison",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Lieu"
  },
  {
    "theme": "Faire corps",
    "title": "AMA Diem",
    "url": "https://www.amadiem.fr",
    "icon": "2 bonhommes + Maison",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association et lieu"
  },
  {
    "theme": "Faire corps",
    "title": "Village landais Alzheimer",
    "url": "https://villagealzheimer.landes.fr",
    "icon": "Maison",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Lieu"
  },
  {
    "theme": "Faire corps",
    "title": "Toît Même",
    "url": "https://www.toitmeme.com",
    "icon": "Maison",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Lieu"
  },
  {
    "theme": "Santé planétaire",
    "title": "Le Jardin - Bron",
    "url": "https://www.ubiclic.com/medecine-generale/bron/le-jardin",
    "icon": "Maison",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Lieu"
  },
  {
    "theme": "Santé planétaire",
    "title": "Alerte Médecins Pesticides",
    "url": "https://alerte-medecins-pesticides.fr",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Santé planétaire",
    "title": "Alliance Santé Planétaire",
    "url": "https://santeplanetaire.org",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Santé planétaire",
    "title": "Réseau RAFUE",
    "url": "https://asso-rafue.com/?PagePrincipale",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Santé planétaire",
    "title": "Société Francophone de Santé Environnement",
    "url": "https://www.sfse.org/accueil",
    "icon": "Stylo",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Société savante, chaire"
  },
  {
    "theme": "Santé planétaire",
    "title": "Hameaux de Santé",
    "url": "https://hameauxdesante.gogocarto.fr/map#/carte/@46.33,2.50,5z?cat=all",
    "icon": "Globe",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Carte"
  },
  {
    "theme": "Santé planétaire",
    "title": "Ecokinés",
    "url": "https://www.ecokines.fr",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Santé planétaire",
    "title": "Ecoveto",
    "url": "https://www.ecoveto.org",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Santé planétaire",
    "title": "Santé Planétaire",
    "url": "https://www.ruedelechiquier.net/essais/386-sante-planetaire.html",
    "icon": "Livres",
    "assets": "",
    "react-icon": "",
    "logo": "FALSE",
    "logoLink": "",
    "label": "Livre, article"
  },
  {
    "theme": "Santé planétaire",
    "title": "Guide du cabinet écoresponsable",
    "url": "https://www.presses.ehesp.fr/produit/guide-cabinet-de-sante-ecoresponsable/",
    "icon": "Livres",
    "assets": "",
    "react-icon": "",
    "logo": "FALSE",
    "logoLink": "",
    "label": "Livre, article"
  },
  {
    "theme": "Santé planétaire",
    "title": "Ooonehealth",
    "url": "https://www.ooonehealth.fr",
    "icon": "Livres",
    "assets": "",
    "react-icon": "",
    "logo": "FALSE",
    "logoLink": "",
    "label": "Livre, article"
  },
  {
    "theme": "Santé communautaire",
    "title": "Réseau des centres de santé communautaire",
    "url": "https://reseau-cdsc.fr",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Santé communautaire",
    "title": "Banlieues Santé",
    "url": "https://banlieues-sante.org",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Santé communautaire",
    "title": "La Place Santé - Saint-Denis",
    "url": "http://acsbe.asso.fr",
    "icon": "Maison",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Lieu"
  },
  {
    "theme": "Santé communautaire",
    "title": "Village 2 Santé - Eschirolles",
    "url": "https://www.levillage2sante.fr",
    "icon": "Maison",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Lieu"
  },
  {
    "theme": "Santé communautaire",
    "title": "La Case de Santé - Toulouse",
    "url": "https://www.casedesante.org",
    "icon": "Maison",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Lieu"
  },
  {
    "theme": "Santé communautaire",
    "title": "Le Château en santé - Marseille",
    "url": "https://www.chateau-en-sante.org",
    "icon": "Maison",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Lieu"
  },
  {
    "theme": "Santé communautaire",
    "title": "Human Santé - Montpellier",
    "url": "https://www.human-sante.org",
    "icon": "Maison",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Lieu"
  },
  {
    "theme": "Santé communautaire",
    "title": "La santé communautaire",
    "url": "https://www.369editions.com/la-sante-communautaire-une-autre-politique-du-soin/",
    "icon": "Livres",
    "assets": "",
    "react-icon": "",
    "logo": "FALSE",
    "logoLink": "",
    "label": "Livre, article"
  },
  {
    "theme": "Santé des femmes",
    "title": "Femmes de santé",
    "url": "https://www.femmesdesante.fr",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Santé des femmes",
    "title": "Donner des ELLES à la santé",
    "url": "https://donnerdeselles.org",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Santé des femmes",
    "title": "Pour une MEUF",
    "url": "https://www.pourunemeuf.org",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Santé des femmes",
    "title": "La maison des femmes - Saint-Denis",
    "url": "https://www.lamaisondesfemmes.fr",
    "icon": "Maison",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Lieu"
  },
  {
    "theme": "Santé des femmes",
    "title": "Maison des femmes Gisèle Halimi - Rennes",
    "url": "https://www.chu-rennes.fr/je-cherche/prise-en-charge-specifique/maison-des-femmes-gisele-halimi-1819.html",
    "icon": "Maison",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Lieu"
  },
  {
    "theme": "Santé des femmes",
    "title": "25 Gisèle-Halimi - Rennes",
    "url": "https://clermont-ferrand.fr/25-gisele-halimi",
    "icon": "Maison",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Lieu"
  },
  {
    "theme": "Santé intégrative",
    "title": "Mû médecine",
    "url": "https://www.mumedecine.org",
    "icon": "Pieds",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Formation, école"
  },
  {
    "theme": "Santé intégrative",
    "title": "CUMIC",
    "url": "https://www.cumic.fr",
    "icon": "Stylo",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Société savante, chaire"
  },
  {
    "theme": "Santé intégrative",
    "title": "L'hirondelle bleue",
    "url": "https://lhirondelle-bleue.fr",
    "icon": "Maison",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Lieu"
  },
  {
    "theme": "Santé intégrative",
    "title": "Institut Rafaël",
    "url": "https://institut-rafael.fr/sante-integrative/",
    "icon": "Maison",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Lieu"
  },
  {
    "theme": "Santé intégrative",
    "title": "NPIS ou Non Pharmacological Intervention Society [en fonction de la place]",
    "url": "https://npisociety.org",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Santé intégrative",
    "title": "Ohm santé intégrative",
    "url": "https://www.ohmsanteintegrative.com",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Santé intégrative",
    "title": "Allié SANTÉ",
    "url": "https://alliesante.net",
    "icon": "2 yeux",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Media"
  },
  {
    "theme": "Soigner la mort",
    "title": "La mort à vivre",
    "url": "https://www.seuil.com/ouvrage/la-mort-a-vivre-catherine-vincent/9782021465679",
    "icon": "Livres",
    "assets": "",
    "react-icon": "",
    "logo": "FALSE",
    "logoLink": "",
    "label": "Livre, article"
  },
  {
    "theme": "Soigner la mort",
    "title": "La maison de Gardanne",
    "url": "https://lamaisondegardanne.org",
    "icon": "Maison",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Lieu"
  },
  {
    "theme": "Soigner la mort",
    "title": "La maison Jeanne Garnier",
    "url": "https://jeanne-garnier.org",
    "icon": "Maison",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Lieu"
  },
  {
    "theme": "Soigner la mort",
    "title": "Coopérative funéraire de Rennes",
    "url": "https://www.lacoopfunerairederennes.fr",
    "icon": "Maison",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Lieu"
  },
  {
    "theme": "Soigner la mort",
    "title": "Collectif National des Maisons de Vie",
    "url": "https://www.cndmv.fr",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Soigner la mort",
    "title": "Convention citoyenne fin de vie",
    "url": "https://conventioncitoyennesurlafindevie.lecese.fr",
    "icon": "Livres",
    "assets": "",
    "react-icon": "",
    "logo": "FALSE",
    "logoLink": "",
    "label": "Livre, article"
  },
  {
    "theme": "Ethique",
    "title": "Espaces éthiques régionaux",
    "url": "https://www.erebfc.fr/les-espaces-de-reflexion-ethique-regionaux/",
    "icon": "Globe",
    "assets": "",
    "react-icon": "",
    "logo": "FALSE",
    "logoLink": "",
    "label": "Carte"
  },
  {
    "theme": "Ethique",
    "title": "Espace éthique IdF",
    "url": "https://www.espace-ethique.org",
    "icon": "Stylo",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Société savante, chaire"
  },
  {
    "theme": "Ethique",
    "title": "Ethique, la vie en question",
    "url": "https://revue-ethique.univ-gustave-eiffel.fr",
    "icon": "Livres",
    "assets": "",
    "react-icon": "",
    "logo": "FALSE",
    "logoLink": "",
    "label": "Livre, article"
  },
  {
    "theme": "Ethique",
    "title": "Chaire de philo",
    "url": "https://chaire-philo.fr",
    "icon": "Stylo",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Société savante, chaire"
  },
  {
    "theme": "Ethique",
    "title": "GEFERS",
    "url": "https://www.gefers.fr",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "FALSE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Ethique",
    "title": "Centre sésame",
    "url": "https://centre-sesame.fr",
    "icon": "Maison",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Lieu"
  },
  {
    "theme": "Soin des soignant·es",
    "title": "Soin aux professionnels de Santé",
    "url": "https://www.asso-sps.fr",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Soin des soignant·es",
    "title": "Association MOTS",
    "url": "https://www.association-mots.org",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Soin des soignant·es",
    "title": "Soins aux étudiants",
    "url": "https://www.soins-aux-etudiants.com",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Soin des soignant·es",
    "title": "LIPSEIM",
    "url": "https://www.lipseim.fr",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Soin des soignant·es",
    "title": "Ikaïros",
    "url": "https://www.instagram.com/ikairos_culturedusoin/",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Soin des soignant·es",
    "title": "CAP CNV Santé",
    "url": "https://capcnvsante.com",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Soin des soignant·es",
    "title": "Les Bazars de la santé",
    "url": "https://www.lesbazarsdelasante.fr",
    "icon": "Maison",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Lieu"
  },
  {
    "theme": "Soin des soignant·es",
    "title": "DIU Soigner les soignants",
    "url": "http://diu-soignerlessoignants.fr",
    "icon": "Pieds",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Formation, école"
  },
  {
    "theme": "Service public",
    "title": "ATD Quart Monde",
    "url": "https://www.atd-quartmonde.fr",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Service public",
    "title": "Collectif Inter Hôpitaux",
    "url": "https://www.collectif-inter-hopitaux.org",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Service public",
    "title": "Collectif Nos Services Publics",
    "url": "https://nosservicespublics.fr",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Service public",
    "title": "L'appel des Appels",
    "url": "http://www.appeldesappels.org",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "FALSE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Service public",
    "title": "Ateliers pour la refondation du service public hospitalier et du soin",
    "url": "https://www.ateliersrefondationhopitalpublic.org",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Service public",
    "title": "Coordination défense santé",
    "url": "https://coordination-defense-sante.org",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Désobéissance civile",
    "title": "Le printemps du care",
    "url": "https://leprintempsducare.org",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Désobéissance civile",
    "title": "Scientifiques en rébellion",
    "url": "https://scientifiquesenrebellion.fr",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Désobéissance civile",
    "title": "Extinction Rebellion",
    "url": "https://extinctionrebellion.fr",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Désobéissance civile",
    "title": "Riposte Alimentaire",
    "url": "https://ripostealimentaire.fr",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Désobéissance civile",
    "title": "Les Soulèvements de la Terre",
    "url": "https://lessoulevementsdelaterre.org",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Désobéissance civile",
    "title": "Action Justice Climat",
    "url": "https://actionjusticeclimat-paris.fr",
    "icon": "2 bonhommes",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Collectif, association"
  },
  {
    "theme": "Désobéissance civile",
    "title": "Terres de lutte",
    "url": "https://terresdeluttes.fr",
    "icon": "Globe",
    "assets": "",
    "react-icon": "",
    "logo": "TRUE",
    "logoLink": "",
    "label": "Carte"
  },
  {
    "theme": "Désobéissance civile",
    "title": "Paye ton burn-out militant",
    "url": "https://payetonburnoutmilitant.fr/de-quoi-on-parle/",
    "icon": "Livres",
    "assets": "",
    "react-icon": "",
    "logo": "FALSE",
    "logoLink": "",
    "label": "Livre, article"
  }
];
