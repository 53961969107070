import NavButton from "../../layouts/NavButton";
import bookExtracts from "./data/bookExtracts";
import ImageCard from "../../components/ImageCard";
import SmallCard from "../../components/SmallCard";
import PageTitle from "../../components/PageTitle";

function Douter() {
    const titre = "Douter...";
    const text = (
        <div>
            <p>
                Des extraits du livre{" "}
                <span className="italic">
                    Mon blanc de travail, un cri du care
                </span>
                .
            </p>
            <br />
            <p>
                Journal de bord du COVID d'une médecin anesthésiste-réanimatrice
                qui doute de l'essence de son métier.
            </p>
        </div>
    );

    return (
        <div className="bg-slate-100">
            <main className="grid-container container">
                <div className="grid-item">
                    <PageTitle title={titre} text={text} />
                </div>
                {bookExtracts.map((card, index) => (
                    <div className="grid-item" key={index}>
                        {/* Display ImageCard on medium and larger screens */}
                        <ImageCard
                            title={card.title}
                            content={card.content}
                            src={card.image}
                            alt={card.alt}
                            imagePosition={card.imagePosition}
                            rgb={card.rgb}
                            index={index}
                        />
                        {/* Display SmallCard on small screens */}
                        <SmallCard
                            className="grid-item block md:hidden"
                            title={card.title}
                            content={card.content}
                            rgb={card.rgb}
                            index={index}
                        />
                    </div>
                ))}
                <NavButton />
            </main>
        </div>
    );
}

export default Douter;
