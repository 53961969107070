const episodes = [
    {
        episode: 1,
        title: "Trahir par fidélité",
        description: (
            <p>
                Dans cet épisode, je pose l'acte de démission ainsi que ses
                raisons, qui seront développées tout le long du podcast et de
                ses 12 épisodes, pour essayer de comprendre la fuite des
                soignant·es comme un symptôme d'une maladie collective.
                <br />
                <br />
                Vous y découvrirez l'histoire d'Elsa, directrice des soins qu'un
                burn out a arrêtée dans sa quête du soin. Le burn out comme une
                forme de trahison du corps par fidélité.
            </p>
        ),
        gender: "female",
        spotifyLink:
            "https://open.spotify.com/episode/1pF30CrfmtIDnsPKGzvo0m?si=1a03d2ac9c30442b",
        deezerLink: "https://deezer.page.link/wbJ7kwpy6tKhPPrW9",
        appleLink: "",
        googleLink:
            "https://podcastaddict.com/j-ai-rendu-la-blouse/episode/182890230",
    },
    {
        episode: 2,
        title: "Apprendre sans le soin",
        description: (
            <p>
                Comment le soin est-il enseigné aux étudiant·es en médecine ?
                Est-il véritablement enseigné, transmis, incarné ? C’est tout
                l’objet de cet épisode qui mêle la voix de Jesse à la mienne.
                Jesse, un interne de médecine démissionnaire qui continue de
                prendre soin, à un autre endroit qu'à l'intérieur de
                l'institution.
            </p>
        ),
        gender: "male",
        spotifyLink:
            "https://open.spotify.com/episode/377xWRsSNFcwx02jHaYN1Y?si=v1pRDOhoSiONYhCjgSLpbQ",
        deezerLink: "https://deezer.page.link/7sdo6sKLJDeWBbLz5",
        appleLink: "",
        googleLink:
            "https://podcastaddict.com/j-ai-rendu-la-blouse/episode/183109945",
    },
    {
        episode: 3,
        title: "Des générations fragiles ?",
        description: (
            <p>
                Il paraitrait que tout ça, tous ces soignants qui démissionnent
                ou s’arrêtent, et plus largement ce monde qui part à vau-l’eau,
                c’est à cause de nous, à cause de nos générations, des
                générations fragiles. Cet épisode propose d’explorer cette
                question en mêlant l’histoire de Joachim, psychiatre de la
                génération Y, à la mienne.
            </p>
        ),
        gender: "male",
        spotifyLink:
            "https://open.spotify.com/episode/4EiJIYIg1mE4mIiQy5w6Aq?si=aa6dcb364b9741a8",
        deezerLink: "https://deezer.page.link/kb8oFEqQC6gh9fy7A",
        appleLink: "",
        googleLink:
            "https://podcastaddict.com/j-ai-rendu-la-blouse/episode/183109944",
    },
    {
        episode: 4,
        title: "Anesthésie émotionnelle",
        description: (
            <p>
                Parmi les causes de la fuite des soignant·es, je soupçonne très
                fortement l’anesthésie émotionnelle individuelle et collective.
                Si cela peut vous paraître peu compréhensible, l’histoire de
                Camélia, chirurgienne gynéco-obstétricienne, devrait vous
                éclairer.
            </p>
        ),
        gender: "female",
        spotifyLink:
            "https://open.spotify.com/episode/7nuBepDHACACk9eaAVYl6U?si=3aDgZxt9TY2RsgGta3exyQ",
        deezerLink: "https://deezer.page.link/fYtKRfUJKkHNcmmP6",
        appleLink: "",
        googleLink:
            "https://podcastaddict.com/j-ai-rendu-la-blouse/episode/183109943",
    },
    {
        episode: 5,
        title: "Abîmer puis réanimer",
        description: (
            <p>
                Et si notre société et notre système de santé semblaient plus
                doués pour tenter de réanimer les individus une fois qu’il les a
                essorés à force de recherche de performance, de normes et de
                gains de productivité, qu’à prendre soin d’eux ? L’histoire
                d’Adeline, infirmière de bloc, semble aller en ce sens.{" "}
            </p>
        ),
        gender: "female",
        spotifyLink:
            "https://open.spotify.com/episode/1hwmleDBWs8KsJkKg9LO42?si=aRgCpF36SEOMrdD1yG-5RA",
        deezerLink: "https://deezer.page.link/8zchcqyg7xfRXkGs5",
        appleLink: "",
        googleLink:
            "https://podcastaddict.com/j-ai-rendu-la-blouse/episode/183109942",
    },
    {
        episode: 6,
        title: "Se sentir complice",
        description: (
            <p>
                Comment faire avec la dissonance cognitive en étant médecin dans
                notre société ? Se sentir complice des inégalités sociales et de
                la crise écologique met les nerfs à rude épreuve. L’histoire
                d’Alicia, médecin généraliste, est plus que parlante pour
                l’illustrer.
            </p>
        ),
        gender: "female",
        spotifyLink:
            "https://open.spotify.com/episode/4awTgAf4A573HsW5lMmqhJ?si=uPv3--Y9TP-TkQrgu32DPg",
        deezerLink: "https://deezer.page.link/M4v2FcQJWkWQXgfB7",
        appleLink: "",
        googleLink:
            "https://podcastaddict.com/j-ai-rendu-la-blouse/episode/183109941",
    },
    {
        episode: 7,
        title: "Ne pas réussir, ne pas consentir",
        description: (
            <p>
                Réussir est parfois bien difficile dans cette société
                hypernormalisée, même en étant médecin. Comment faire lorsque
                l’on est femme ? Comment faire lorsque l’on est femme, racisée
                et musulmane ? Et qu’est-ce que réussir ? Comment oser dire les
                dysfonctionnements du quotidien lorsque ça vient ébranlé le
                récit d'une société qui se raconte que tout va bien ? Le
                portrait d’Hiba, pédiatre, nous donne des pistes pour avancer
                sur le chemin du respect de toustes. Semer des graines encore et
                encore.{" "}
            </p>
        ),
        gender: "female",
        spotifyLink:
            "https://open.spotify.com/episode/3SP2De0ykkwGPn1hTZj59X?si=181ff735443b48f4",
        deezerLink: "https://deezer.page.link/YkGfGcwi5N3oRpFW7",
        appleLink: "",
        googleLink:
            "https://podcastaddict.com/j-ai-rendu-la-blouse/episode/183109940",
    },
    {
        episode: 8,
        title: "Trop sensible",
        description: (
            <p>
                Notre sensibilité n’est-elle réellement qu’une faiblesse ? Ne
                pourrait-elle pas être une richesse ? Une ressource au service
                du dialogue ? Y compris entre différentes formes de soin, entre
                différents besoins des patient·es. Cloé, oncologue, nous y
                invite.
            </p>
        ),
        gender: "female",
        spotifyLink:
            "https://open.spotify.com/episode/6Aa1xSjnETD842p2GzEALm?si=NWCMUliUQAOh1dJutk4FXg",
        deezerLink: "https://deezer.page.link/hKY9rEedGM3QYF31A",
        appleLink: "",
        googleLink:
            "https://podcastaddict.com/j-ai-rendu-la-blouse/episode/183109939",
    },
    {
        episode: 9,
        title: "Crise identitaire",
        description: (
            <p>
                Laura, une patiente pas comme les autres ? Quoique ? Ne
                sommes-nous pas toustes à la fois patient·es et soignant·es ?
                Que pourrions-nous gagner à nous défaire un peu de nos
                étiquettes ?{" "}
            </p>
        ),
        gender: "female",
        spotifyLink:
            "https://open.spotify.com/episode/7zilZO1P34ZVnMqzOWstSn?si=9a25a4bf581845a7",
        deezerLink: "https://deezer.page.link/GnzTtpUw1odBbHn76",
        appleLink: "",
        googleLink:
            "https://podcastaddict.com/j-ai-rendu-la-blouse/episode/183109949",
    },
    {
        episode: 10,
        title: "Crise existentielle",
        description: (
            <p>
                Qu’est-ce que la mort ? Quel est notre rapport collectif à la
                mort ? Comment être médecin dans une société qui déni la mort
                et/ou les émotions qui lui sont liées ? Pourrions-nous gagner en
                sérénité individuelle et collective en échangeant sur la mort ?
                En osant parler de notre peur de la mort ? De notre impuissance
                face à la mort ?
            </p>
        ),
        gender: "female",
        spotifyLink:
            "https://open.spotify.com/episode/5P5aDQPcinkozWg1djrF0L?si=5-FsEzAITVKXEp0cIQT6RA",
        deezerLink: "https://deezer.page.link/H4Ug7bnwAJWbxoax8",
        appleLink: "",
        googleLink:
            "https://podcastaddict.com/j-ai-rendu-la-blouse/episode/183109948",
    },
    {
        episode: 11,
        title: "Part monstrueuse",
        description: (
            <p>
                Suzanne nous invite dans son quotidien d'aide-soignante de
                réanimation. Confrontée à la violence ordinaire et aux malheurs
                de la vie, elle tient bon. Elle garde le soin chevillé au corps
                et au coeur. Car résister, ça n’est pas toujours démissionner,
                partir, se barrer, c’est parfois rester et tenir bon dans la
                tempête.
                <br />
                <br />
                Peut-être que pour réussir cet exploit quotidien, nous avons
                besoin d'aller voir la part nazie en chacun·e d’entre nous. Je
                crois que cela pourrait être une forme d’hygiène émotionnelle
                collective. Pour mieux nous soutenir. Pour réussir à faire avec
                le quotidien. Pour essayer de préserver notre intégrité instant
                après instant, comme Suzanne.{" "}
            </p>
        ),
        gender: "female",
        spotifyLink:
            "https://open.spotify.com/episode/1K03xwJl0bIe1EfDBf9TmF?si=271edec1bcc34b2e",
        deezerLink: "https://deezer.page.link/5XvkbX1qSkK9fXv67",
        appleLink: "",
        googleLink:
            "https://podcastaddict.com/j-ai-rendu-la-blouse/episode/183109947",
    },
    {
        episode: 12,
        title: "Vulnérabilité collective",
        description: (
            <p>
                Jean est chirurgien. Il est professeur de chirurgie. Il s’est
                donné toutes ces années pour en arriver là. Mais qu'en est-il de
                sa vulnérabilité maintenant qu'il vieillit et s'approche de la
                retraite ? Quelles sont les ressources collectives pour
                l'accompagner dans ses dernières années d'exercice professionnel
                et son passage à la retraite ? Avons-nous conscience de nos
                vulnérabilités individuelles et plus encore de nos
                vulnérabilités collectives ?
            </p>
        ),
        gender: "male",
        spotifyLink:
            "https://open.spotify.com/episode/3VhxJQmEBnVZrHSS1NkGsH?si=3c93576c091a4050",
        deezerLink: "https://deezer.page.link/4VCRQr3a6NBkohvj9",
        appleLink: "",
        googleLink:
            "https://podcastaddict.com/j-ai-rendu-la-blouse/episode/183109946",
    },
];

export default episodes;
