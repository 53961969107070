import React, { useState, useRef, useEffect } from "react";

const PlayButton = ({ track }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);

    useEffect(() => {
        // Créer l'instance Audio lorsque le composant est monté
        audioRef.current = new Audio(track);

        // Gérer la fin de la piste
        const handleAudioEnded = () => {
            setIsPlaying(false);
        };

        audioRef.current.addEventListener("ended", handleAudioEnded);

        // Nettoyage lors du démontage
        return () => {
            audioRef.current.removeEventListener("ended", handleAudioEnded);
            audioRef.current.pause();
            audioRef.current = null; // Nettoyage de l'instance audio
        };
    }, [track]);

    const handlePlayPause = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play().catch((error) => {
                console.error("Error playing audio:", error);
            });
        }
        setIsPlaying(!isPlaying);
    };

    return (
        <button
            onClick={handlePlayPause}
            className="relative flex h-16 w-16 items-center justify-center rounded-full border-2 border-black shadow-lg hover:bg-gray-200 focus:outline-none"
        >
            {/* Icône de lecture ou pause en fonction de l'état */}
            {isPlaying ? (
                // Icône pause
                <div className="flex space-x-1">
                    <div className="h-6 w-2 bg-black"></div>
                    <div className="h-6 w-2 bg-black"></div>
                </div>
            ) : (
                // Icône play (triangle)
                <div
                    className="h-0 w-0 border-b-[12px] border-l-[20px] border-t-[12px] border-b-transparent border-l-black border-t-transparent"
                    style={{ marginLeft: "4px" }} // Centrer le triangle
                ></div>
            )}
        </button>
    );
};

export default PlayButton;
