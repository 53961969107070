import { Link } from "react-router-dom";

function TextLink({ link, title }) {
    return (
        <Link
            to={link}
            className="text-primary-bold transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
        >
            {title}
        </Link>
    );
}

export default TextLink;
