import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import BurgerIcon from "./components/BurgerIcon";
import BurgerModal from "./components/BurgerModal";
import MbdtLogo from "../../components/logos/MbdtLogo";
import "./header.css";

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [isFixed, setIsFixed] = useState(false);
    const [hasTransition, setHasTransition] = useState(true);
    const headerRef = useRef(null);
    const [height, setHeight] = useState(0);
    const location = useLocation();
    const [lastScrollY, setLastScrollY] = useState(0);
    const [isSmallSize, setSmallSize] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen((prevState) => !prevState);
    };

    // Gérer l'apparition de la modale au bon endroit
    useEffect(() => {
        const handleResize = () => {
            if (headerRef.current) {
                const { height } = headerRef.current.getBoundingClientRect();
                setHeight(height);
            }
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // Gérer l'apparition/disparition du header lors du scroll
    const controlHeader = () => {
        const isSmallScreen = window.innerWidth < 768;

        if (isSmallScreen) {
            // Comportement pour les petits écrans
            if (window.scrollY === 0) {
                // Si l'utilisateur est tout en haut, le header doit redevenir relatif
                setIsFixed(false);
            } else if (window.scrollY > lastScrollY) {
                // Si l'utilisateur fait défiler vers le bas, cacher le header
                setIsVisible(false);
                setIsFixed(true); // Passer en fixed
                setHasTransition(true);
            } else {
                // Si l'utilisateur fait défiler vers le haut, afficher le header
                setIsVisible(true);
            }
        } else {
            // Comportement pour les grands écrans (à partir de sm)
            if (window.scrollY > 0) {
                // Fixer le header lorsque l'utilisateur scroll vers le bas
                setIsFixed(true);
                setSmallSize(true);
            } else {
                // Si l'utilisateur fait défiler vers le haut ou est en haut de la page
                setIsFixed(false);
                setSmallSize(false);
            }
        }
        setLastScrollY(window.scrollY); // Mettre à jour la position du scroll
    };

    useEffect(() => {
        window.addEventListener("scroll", controlHeader);

        // Cleanup du listener
        return () => {
            window.removeEventListener("scroll", controlHeader);
        };
    });

    useEffect(() => {
        // Réinitialiser le header lors du changement de page
        setIsVisible(true);
        setIsFixed(false);
        setHasTransition(false);

        // Réactiver la transition après le premier défilement vers le bas
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setHasTransition(true);
                window.removeEventListener("scroll", handleScroll);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [location]);

    return (
        <div
            id="header"
            className={`header ${isVisible ? "visible" : "hidden"} ${isFixed ? "fixed" : ""} ${!hasTransition ? "no-transition" : ""} ${isSmallSize ? "reduced-size" : ""}`}
            ref={headerRef}
        >
            <MbdtLogo isSmallSize={isSmallSize} />
            <h1 className="font-hand w-full text-center text-2xl sm:text-3xl md:hidden">
                Mon Blanc de Travail
            </h1>
            <div className="hidden justify-end md:block">
                <Navbar />
            </div>
            <div className="md:hidden">
                <BurgerIcon toggleMenu={toggleMenu} isOpen={isMenuOpen} />
            </div>
            {isMenuOpen && (
                <BurgerModal
                    toggleMenu={toggleMenu}
                    isOpen={isMenuOpen}
                    height={height}
                />
            )}
        </div>
    );
}

export default Header;
