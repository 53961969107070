import TextLink from "../../components/TextLink";
import NavButton from "../../layouts/NavButton";
import PageTitle from "../../components/PageTitle";
import Map from './components/Map';
import tisser from "../../assets/images/cheminer/large/5_tisser.webp";
import "../styles.css";
import {data} from './data/data.js';  // Make sure to import your data

function Tisser() {
    const titre = "Tisser";
    const text = (
        <div>
            <p>
                Une carte partielle et partiale des{" "}
                <span className="font-bold">des émergences en santé.</span>
            </p>
            <br />
            <p className="italic">
                "La carte n'est pas le territoire, mais elle aide à se repérer."
            </p>
            <br />
            <p>
                Santé intégrative, planétaire, communautaire, quel que soit le
                nom : <br /> des personnes, lieux, associations, collectifs,
                médias, qui esquissent un visage de la santé en recomposition.
            </p>
            <br />
            <p>
                Soutenir l'existant ou nourrir l'émergent, peu importe ! Du
                moment que l'intention de prendre soin est là.
            </p>
        </div>
    );

    return (
        <div className="bg-slate-50 py-8">
            <div className="grid-container container">
                <div className="grid-item">
                    <PageTitle title={titre} text={text} />
                </div>
                <div className="grid-item">
                    <h1 className="bg-yellow-400 text-center text-3xl">
                        ! PAGE ACTUELLEMENT EN CONSTRUCTION !
                    </h1>
                </div>
                <div className="grid-item">
                    <img
                        src={tisser}
                        alt="MindMap"
                        className="border border-black"
                        loading="lazy"
                    />
                </div>
                {/* <div className="grid-item">
                    <iframe
                        title="mindmap"
                        width="768"
                        height="432"
                        src="https://miro.com/app/live-embed/uXjVLcY2cck=/?moveToViewport=-1116,-971,2161,2059&embedId=277391098431"
                        frameBorder="0"
                        scrolling="no"
                        allow="fullscreen; clipboard-read; clipboard-write"
                        allowFullScreen
                    ></iframe>
                </div> */}
                <div className="grid-item w-full">
                    <Map data={data} />
                </div>
                <div className="grid-item w-full">
                    <p className="font-text text-justify">
                        Cette carte n'aurait jamais existé si je n'avais pas
                        fait tout ce <TextLink link={"/"} title={"chemin"} />.{" "}
                        <TextLink link={"/douter"} title={"Douter"} /> tout
                        d'abord du sens de mon métier, jusqu'à{" "}
                        <TextLink link={"/renoncer"} title={"renoncer"} /> à
                        l'exercer voire à exercer tout court. Et puis, de
                        rencontre en rencontre ; de dialogue, en chemin, en
                        pause, en réflexion, en échange, en soin, j'ai renoncé à
                        attendre qu'une solution vienne "d'en haut", des
                        "représentant·es". J'ai{" "}
                        <TextLink link={"/faire-corps"} title={"fait corps"} />{" "}
                        avec d'autres. C'est ainsi que cette carte est née pour
                        tenter de tisser entre toutes les personnes de terrain
                        qui cherchent à faire de la santé un bien commun, et du
                        soin une intention de faire société.
                    </p>
                </div>
                <NavButton />
            </div>
        </div>
    );
}

export default Tisser;
