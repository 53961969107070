import React from 'react';

const PetalLink = ({ title, link, icon, logo, logoLink, fontSize }) => {
  return (
    <a
      href={link}
      className="group flex items-center gap-0.5 rounded-full px-1 py-0.5 transition-all duration-200 cursor-pointer hover:bg-white/80"
      target="_blank"
      rel="noopener noreferrer"
      title={title}
    >
      {/* Title */}
      <span 
        className="text-gray-600 group-hover:text-blue-600 whitespace-nowrap"
        style={{ 
          fontSize: `${fontSize * 0.8}rem`,
          maxWidth: `${fontSize * 120}px`
        }}
      >
        {title}
      </span>

      {/* Logo if present */}
      {logo && logoLink && (
        <img 
          src={logoLink} 
          alt={`${title} logo`}
          style={{
            width: `${fontSize * 1.2}rem`,
            height: `${fontSize * 1.2}rem`
          }}
          className="object-contain"
          loading="lazy"
        />
      )}
    </a>
  );
};

export default PetalLink; 