import NavButton from "../../layouts/NavButton";
import PageTitle from "../../components/PageTitle";
import PodcastTrailer from "./components/PodcastTrailer";
import Episode from "./components/Episode";
import episodes from "./data/episodes";
import "../styles.css";

function Renoncer() {
    const titre = "Renoncer";
    const text = (
        <div>
            <p>
                <span className="italic">J'ai rendu la blouse</span>, un podcast
                en 12 épisodes.
            </p>
            <br />

            <p>
                Raconter pourquoi j'ai démissionné d'un métier que j'aimais
                tant, médecin anesthésiste-réanimatrice, en mêlant ma voix aux
                portraits de 12 soignantes et soignants.
            </p>
        </div>
    );

    return (
        <div className="bg-secondary-light">
            <div className="grid-container container">
                <div className="grid-item">
                    <PageTitle title={titre} text={text} />
                </div>
                <div className="grid-item">
                    <PodcastTrailer />
                    {episodes.map((episode, index) => (
                        <div key={index}>
                            <Episode episode={episode} />
                        </div>
                    ))}
                    <div className="h-2 rounded-b-lg border border-t-0 border-gray-300 bg-white"></div>
                </div>
                <NavButton />
            </div>
        </div>
    );
}

export default Renoncer;
