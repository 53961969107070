import oops from "../../assets/images/404/oups.png";
import "../styles.css";

function NotFound() {
    return (
        <div className="bg-primary-light background">
            <main className="grid-container container">
                <div className="grid-item items-center text-center">
                    <h1 className="pt-8 text-3xl text-red-600 sm:text-5xl">
                        !!  404 page non trouvée  !!
                    </h1>
                    <br />
                    <p className="text-2xl italic">
                        Le site est en construction, les pages ne sont pas
                        toutes disponibles...
                    </p>
                    <img
                        src={oops}
                        alt="oups"
                        className="m-auto"
                        loading="lazy"
                    />
                </div>
            </main>
        </div>
    );
}

export default NotFound;
