import pageLinks from "../Header/pageLinks";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import "../../pages/styles.css";

const NavButton = () => {
    const location = useLocation(); // Récupère l'URL actuelle
    const currentPage = location.pathname; // URL actuelle (ex: "/douter")

    // Trouver l'index de la page actuelle
    const currentIndex = pageLinks.findIndex(
        (page) => page.link === currentPage,
    );

    // Déterminer les liens précédent et suivant
    const prevPage = currentIndex > 0 ? pageLinks[currentIndex - 1] : null;
    const nextPage =
        currentIndex < pageLinks.length - 1
            ? pageLinks[currentIndex + 1]
            : null;

    return (
        <div className="grid-item flex w-full justify-between">
            {/* Bouton précédent */}
            {prevPage ? (
                <Link
                    to={prevPage.link}
                    className="hover:text-primary-bold flex items-center gap-2 text-gray-700 transition-colors hover:text-lg lg:text-xl lg:hover:text-2xl"
                >
                    <HiChevronLeft size={24} /> {/* Icône flèche gauche */}
                    <span className="uppercase">{prevPage.title}</span>
                </Link>
            ) : (
                <div className="" />
            )}

            {/* Bouton suivant */}
            {nextPage ? (
                <Link
                    to={nextPage.link}
                    className="hover:text-primary-bold flex items-center gap-2 text-gray-700 transition-colors hover:text-lg lg:text-xl lg:hover:text-2xl"
                >
                    <span className="uppercase">{nextPage.title}</span>
                    <HiChevronRight size={24} /> {/* Icône flèche droite */}
                </Link>
            ) : (
                <div className="" />
            )}
        </div>
    );
};

export default NavButton;
