function PageTitle({ title, text }) {
    return (
        <div className="flex w-full flex-col items-center justify-center gap-4 p-4 md:flex-row">
            <h1 className="font-hand justify-center pb-4 text-center text-4xl sm:text-5xl md:w-1/3">
                {title}
            </h1>
            <div className="font-text text-left md:w-2/3 md:text-justify">
                {text}
            </div>
        </div>
    );
}

export default PageTitle;
