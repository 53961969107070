import linkDatas from "./linkDatas";
import FooterLink from "./components/FooterLink";
import SocialMediaLogo from "../../components/logos/SocialMediaLogo";
import linkedIn from "../../assets/images/linkedIn.webp";
import instagram from "../../assets/images/instagram.webp";
import youtube from "../../assets/images/youtube.webp";

function Footer() {
    return (
        <div id="footer" className="mx-auto w-full bg-slate-200 p-4">
            <h1
                id="titre"
                className="font-hand row-span-2 text-center text-4xl"
            >
                Mon Blanc de Travail
            </h1>
            <hr className="border-primary-bold mt-4"></hr>
            <div id="container" className="mx-8 my-4 w-full">
                <div
                    id="link-container"
                    className="grid grid-flow-row grid-cols-1 justify-items-start gap-4 md:grid-cols-2"
                >
                    {linkDatas.map((link, index) => (
                        <FooterLink
                            key={index}
                            name={link.name}
                            link={link.link}
                        />
                    ))}
                    <div id="social-container" className="flex gap-4">
                        <SocialMediaLogo
                            src={linkedIn}
                            name={"LinkedIn"}
                            link={
                                "https://fr.linkedin.com/in/margot-smirdec-264023213"
                            }
                        />
                        <SocialMediaLogo
                            src={instagram}
                            name={"instagram"}
                            link={
                                "https://www.instagram.com/mon_blanc_de_travail/"
                            }
                        />
                        <SocialMediaLogo
                            src={youtube}
                            name={"youtube"}
                            link={"https://www.youtube.com/watch?v=iSM8cuI2hok"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
