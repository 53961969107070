import { Link } from "react-router-dom";
import "../../styles.css";

function ConditionsGeneralesUtilisation() {
    return (
        <div className="bg-primary-light">
            <div className="grid-container container">
                <div className="grid-item">
                    <h1 className="font-hand text-center text-5xl">
                        Conditions Générales d'Utilisation
                    </h1>
                    <p className="text-center">Version du 10 décembre 2024</p>
                    <h3 className="font-text py-4 text-2xl">Préambule</h3>
                    <p>
                        Les présentes Conditions Générales d'Utilisation
                        (ci-après "CGU") régissent l'accès et l'utilisation du
                        site web accessible à l'adresse{" "}
                        <a
                            href="https://monblancdetravail.fr"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            https://monblancdetravail.fr
                        </a>{" "}
                        (ci-après "le Site"). En accédant au Site, l'utilisateur
                        accepte pleinement et sans réserve les présentes CGU.
                    </p>
                    <h3 className="font-text py-4 text-2xl">Objet du site</h3>
                    <p>
                        Le Site a pour objectif principal de partager un
                        parcours de vie, des idées, des associations et des
                        personnes. Les informations diffusées sont données à
                        titre informatif et n'engagent pas la responsabilité de
                        l'Éditeur.
                    </p>
                    <h3 className="font-text py-4 text-2xl">Accès au site</h3>
                    <p>
                        Le Site est accessible gratuitement à tout utilisateur
                        disposant d’un accès à Internet. Les frais liés à
                        l'accès au Site (équipement, connexion Internet, etc.)
                        sont à la charge de l'utilisateur.
                    </p>
                    <h3 className="font-text py-4 text-2xl">
                        Propriété intellectuelle
                    </h3>
                    <p>
                        Tous les contenus présents sur le Site (textes, images,
                        logos, vidéos, etc.) sont protégés par les lois en
                        vigueur sur la propriété intellectuelle. Toute
                        reproduction, représentation, modification, publication
                        ou adaptation, totale ou partielle, des éléments du Site
                        est interdite sans l'autorisation écrite préalable de
                        l'Éditeur.
                    </p>
                    <h3 className="font-text py-4 text-2xl">
                        Responsabilité de l'utilisateur
                    </h3>
                    <p>
                        L'utilisateur s'engage à utiliser le Site de manière
                        responsable, à ne pas perturber son fonctionnement et à
                        ne pas y effectuer d'actes malveillants (tentatives de
                        piratage, diffusion de virus, etc.).
                    </p>
                    <h3 className="font-text py-4 text-2xl">
                        Limitation de responsabilité
                    </h3>
                    <p>
                        L'Éditeur met tout en œuvre pour assurer l'exactitude
                        des informations publiées sur le Site, mais ne garantit
                        pas leur complétude ou leur actualité. L'utilisation des
                        informations présentes sur le Site se fait sous la
                        responsabilité de l'utilisateur.
                    </p>
                    <h3 className="font-text py-4 text-2xl">
                        Liens hypertextes
                    </h3>
                    <p>
                        Le Site peut contenir des liens hypertextes vers des
                        sites tiers. L'Éditeur ne saurait être tenu responsable
                        du contenu de ces sites tiers ou de tout dommage pouvant
                        survenir lors de leur utilisation.
                    </p>
                    <h3 className="font-text py-4 text-2xl">
                        Modification des CGU
                    </h3>
                    <p>
                        L'Éditeur se réserve le droit de modifier les présentes
                        CGU à tout moment. Les utilisateurs seront informés de
                        ces modifications via une publication sur cette page
                        avec la date de mise à jour.
                    </p>
                    <h3 className="font-text py-4 text-2xl">Contact</h3>
                    <p>
                        Pour toute question d’information relative aux CGU, vous
                        pouvez me contacter via le{" "}
                        <Link
                            to="/contact"
                            className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                        >
                            formulaire de contact
                        </Link>
                        .
                    </p>
                </div>
            </div>
        </div>
    );
}

export default ConditionsGeneralesUtilisation;
