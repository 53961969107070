import { useEffect } from "react";
import { Link } from "react-router-dom";
import pageLinks from "../../pageLinks";

function BurgerLink({ link, title, toggleMenu }) {
    return (
        <Link
            className="font-text block w-full py-2 text-center hover:bg-gray-700"
            to={link}
            onClick={toggleMenu}
        >
            {title}
        </Link>
    );
}

function BurgerModal({ isOpen, height, toggleMenu }) {
    // Cliquer à l'extérieur pour fermer le menu
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                isOpen &&
                !document.getElementById("menu").contains(event.target) &&
                !document.getElementById("menu-button").contains(event.target)
            ) {
                toggleMenu();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen, toggleMenu]);

    return (
        <div
            id="menu"
            className={`${
                isOpen
                    ? "translate-x-0 opacity-100"
                    : "-translate-x-full opacity-0"
            } absolute right-0 z-50 flex w-full flex-col items-center justify-between bg-gray-600 text-white transition-all duration-300`}
            style={{ top: `${height}px` }}
        >
            {pageLinks.map((page, index) => (
                <BurgerLink
                    key={index}
                    title={page.title}
                    link={page.link}
                    toggleMenu={toggleMenu}
                />
            ))}
        </div>
    );
}

export default BurgerModal;
