import React, { useState } from "react";
import {
    FaSpotify,
    FaDeezer,
    FaChevronRight,
    FaChevronUp,
} from "react-icons/fa";
import { SiApplepodcasts, SiPodcastaddict } from "react-icons/si";
import RandomPortrait from "../RandomPortrait";

const Episode = ({ episode }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="w-full border border-b-0 border-gray-300 bg-white p-4">
            {/* Bandeau avec le titre et le bouton */}
            <div
                className={`flex cursor-pointer items-center justify-between ${isOpen ? "text-primary-bold" : "hover:text-primary-bold text-gray-600"}`}
                onClick={() => setIsOpen(!isOpen)}
            >
                <h2 className="font-subtitle text-xl font-bold">
                    Episode {episode.episode} : {episode.title}
                </h2>
                {/* Bouton pour changer l'état isOpen */}
                <div className="p-2">
                    {isOpen ? (
                        <FaChevronUp size={20} />
                    ) : (
                        <FaChevronRight size={20} />
                    )}
                </div>
            </div>

            {/* Contenu développé si isOpen est true */}
            <div
                className={`transition-all duration-500 ease-in-out ${
                    isOpen ? "max-h-[500px] opacity-100" : "max-h-0 opacity-0"
                } overflow-hidden`}
                style={{
                    maxHeight: isOpen ? "500px" : "0",
                    opacity: isOpen ? "1" : "0",
                    transition:
                        "max-height 0.5s ease-in-out, opacity 0.5s ease-in-out",
                }}
            >
                {/* Séparateur */}
                <hr className="my-3 border-gray-200" />

                {/* Description et icônes */}
                <div className="mt-3 flex flex-col-reverse gap-4 px-6 md:flex-row">
                    <div className="flex flex-1 flex-col gap-2">
                        <div className="font-text flex-1 text-justify text-gray-700">
                            {episode.description}
                        </div>

                        <div className="flex items-center space-x-4">
                            <a
                                href={episode.spotifyLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-green-500 hover:text-green-700"
                            >
                                <FaSpotify size={30} />
                            </a>
                            <a
                                href={episode.deezerLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 hover:text-blue-700"
                            >
                                <FaDeezer size={30} />
                            </a>
                            <a
                                href={episode.appleLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="hidden text-purple-500 hover:text-purple-700"
                            >
                                <SiApplepodcasts size={30} />
                            </a>
                            <a
                                href={episode.googleLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-orange-500 hover:text-orange-700"
                            >
                                <SiPodcastaddict size={30} />
                            </a>
                        </div>
                    </div>
                    {/* <div className="flex items-center justify-center">
                        <RandomPortrait gender={episode.gender} />
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Episode;
